import { Pageable } from "../models/types/Pageable";
import {
  SurveyInstanceFilters,
  SurveyInstanceResponseType as InstanceResponse,
} from "../models/types/SurveyInstanceFilters";
import { useCallback } from "react";
import axios from "axios";
import { ShareTypes } from "../models/enums/ShareTypes";
import { MainApiUrl } from "../helpers/StringConstants";

interface IAnswersApi {
  getSurveyInstances: (shareId: string, page: Pageable, filters: SurveyInstanceFilters) => Promise<InstanceResponse>;
}

const MainUrl = MainApiUrl;
const toUrl = (parts: string[]) => MainUrl.concat(parts.join("/"));

export const useAnswersApi = (shareType: ShareTypes): IAnswersApi => {
  const getSurveyInstances = useCallback(
    async (shareId: string, pageable: Pageable, filters: SurveyInstanceFilters) => {
      try {
        const { data } = await axios.post<InstanceResponse>(
          toUrl(["/results", shareType.toLowerCase(), shareId]),
          filters,
          {
            params: { ...pageable, total: undefined },
          },
        );
        return Promise.resolve(data);
      } catch (e) {
        return Promise.reject(e);
      }
    },
    [],
  );

  return {
    getSurveyInstances: getSurveyInstances,
  };
};
