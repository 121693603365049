import "./index.css";
import ApplicationLayout from "../components/ApplicationLayout";
import { ApplicationRoutes } from "../routes";
import { AxiosInterceptor } from "../helpers/AxiosInterceptor";
import { AppContextProvider } from "./context";
import StylesProvider from "../components/StylesProvider";

const Application = () => {
  return (
    <AppContextProvider>
      <StylesProvider>
        <ApplicationLayout>
          <AxiosInterceptor>
            <ApplicationRoutes />
          </AxiosInterceptor>
        </ApplicationLayout>
      </StylesProvider>
    </AppContextProvider>
  );
};

export default Application;
