import { FC, useCallback, useEffect, useState } from "react";
import { GeneralStatisticType } from "../../models/types/GeneralStatisticType";
import { convertListStatisticsToObj } from "../../helpers/ResultsPageHelpres";
import { useReviewsApi } from "../../requests/ReviewsApi";
import GeneralStatisticsComponent from "../../components/GeneralStatisticsComponent";
import StatisticsLayout from "../../components/StatisticsLayout";
import { useAppContext } from "../../application/context";

type Props = {
  readonly shareId: string;
  readonly showTitle?: boolean;
  readonly bordered?: boolean;
  readonly showQuestionsQuantity?: boolean;
};

const GeneralStatistics: FC<Props> = ({ shareId, showTitle, bordered = true, showQuestionsQuantity }) => {
  const { data: ctxData, password } = useAppContext();
  const { getTotalStatistics } = useReviewsApi(ctxData.shareType);
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<GeneralStatisticType | undefined>();

  const loadData = useCallback(() => {
    setLoading(true);
    getTotalStatistics(shareId, password)
      .then((res) => setData(convertListStatisticsToObj(res)))
      .catch(console.log)
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    loadData();
  }, []);

  if (!bordered) {
    return <GeneralStatisticsComponent data={data} loading={isLoading} showQuestionsQuantity={showQuestionsQuantity} />;
  }

  return (
    <StatisticsLayout title={showTitle ? "Общая статистика" : undefined} padding={"40px 60px"}>
      <GeneralStatisticsComponent data={data} loading={isLoading} />
    </StatisticsLayout>
  );
};

export default GeneralStatistics;
