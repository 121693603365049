export const DefaultDateTimeFormat = "YYYY-MM-DD HH:mm:ss";
export const DefaultDateFormat = "YYYY-MM-DD";
export const BackgroundLightGrayColor = "#f0f2f5";
export const AppPrefix = window._env_.PUBLIC_URL;
export const TextLightColor = "rgba(185,183,183,0.52)";
export const MainApiUrl = window._env_.API_BASE_URL + window._env_.API_PATH;
export const TechDateTimeFormat = "YYYY-MM-DDTHH:mm:ss";
export const CtxDatKey = "ctxData ";
export const AccessTokenKey = "accessToken ";
export const RedirectUrlKey = "redirectUrl ";
