import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { DonutChart } from "../../components/Charts";
import { ChartViewTypes } from "../../models/enums/ChartViewTypes";
import StatisticsLayout from "../../components/StatisticsLayout";
import { LabelValueList } from "../../models/types/LabelValue";
import { mapValuesFromStringToNumber } from "../../helpers/ResultsPageHelpres";
import { useReviewsApi } from "../../requests/ReviewsApi";
import { useAppContext } from "../../application/context";

type Props = { readonly shareId: string };

const InteractionsByCategories: FC<Props> = ({ shareId }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
      <Chart
        shareId={shareId}
        type={ChartViewTypes.DEVICE}
        subTitle={"По типу устройства"}
        title={"Кол-во взаимодействий по категориям"}
      />
      <Chart shareId={shareId} type={ChartViewTypes.LANGUAGE} subTitle={"По языку"} />
      <Chart shareId={shareId} type={ChartViewTypes.MAILING} subTitle={"По типу рассылки"} />
    </div>
  );
};

export default InteractionsByCategories;

type ChartProps = {
  readonly shareId: string;
  readonly type: ChartViewTypes;
  readonly title?: string;
  readonly subTitle: string;
};

const Chart: FC<ChartProps> = ({ shareId, type, title, subTitle }) => {
  const { data, loading } = useLoadDataByCategory(type, shareId);
  return (
    <StatisticsLayout
      title={title}
      padding={"20px"}
      subTitle={subTitle}
      children={<DonutChart data={data} height={350} loading={loading} />}
    />
  );
};

const useLoadDataByCategory = (type: ChartViewTypes, shareId: string) => {
  const { data: ctxData, password } = useAppContext();
  const { getInteractionsByCategory } = useReviewsApi(ctxData.shareType);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<LabelValueList>([]);

  const loadData = useCallback(() => {
    setLoading(true);
    getInteractionsByCategory(shareId, type, password)
      .then((res) => setData(mapValuesFromStringToNumber(res)))
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [type]);

  useEffect(() => loadData(), []);

  return useMemo(() => {
    return { data, loading, reRetch: loadData };
  }, [data, loading]);
};
