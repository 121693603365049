import { CSSProperties, FC, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Tabs } from "antd";

import { MapApplicationPathToPathWithPrefix as map } from "../helpers/MapApplicationPathToPathWithPrefix";
import { ApplicationPaths } from "../models/enums/ApplicationPaths";
import { ApplicationPathsTitle } from "../helpers/RecordConstants";

const TabBarStyles: CSSProperties = { fontWeight: 600, fontSize: 29 };

const toLabel = (key: ApplicationPaths) => ApplicationPathsTitle[key];
const toItem = (key: ApplicationPaths) => ({ label: toLabel(key), tabKey: map(key), key: map(key) });
const Items = Object.values(ApplicationPaths).map(toItem);

const toTabKey = (pathname: string) => {
  return "/".concat(pathname.split("/").at(-1) ?? "");
};

const toPath = (newTabKey: string, prevTabKey: string, pathname: string): string => {
  return pathname.replace(prevTabKey, newTabKey);
};

const Navigation: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const activeKey = toTabKey(location.pathname);

  const handleTabChange = useCallback(
    (key: string) => {
      const path = toPath(key, activeKey, location.pathname);
      navigate(path);
    },
    [activeKey, location.pathname],
  );

  return (
    <Tabs
      type={"line"}
      items={Items}
      size={"large"}
      activeKey={activeKey}
      tabBarStyle={TabBarStyles}
      onChange={handleTabChange}
    />
  );
};

export default Navigation;
