import axios, { AxiosError, AxiosResponse } from "axios";
import { message } from "antd";
import { FC } from "react";
import { ChildrenNode } from "../models/types/ChildrenNode";
import { useAppContext } from "../application/context";
import { ShareTypes } from "../models/enums/ShareTypes";

export const AxiosInterceptor: FC<ChildrenNode> = (props) => {
  const { children } = props;
  const { accessToken, data, clearToken, validateAccess } = useAppContext();

  axios.interceptors.request.use((request: any) => {
    if (accessToken && data.shareType === ShareTypes.PERSONALISED) {
      request.headers = {
        ...request.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }
    return request;
  });

  axios.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      if (
        (error?.response?.status === 401 || error.status === 401) &&
        accessToken &&
        data.shareType === ShareTypes.PERSONALISED
      ) {
        clearToken();
        validateAccess();
      }
      message.error(error.message ?? error.response?.statusText ?? "Something went wrong").then();
      throw error;
    },
  );
  return <>{children}</>;
};
