function formatTime(seconds: number): string {
  if (seconds >= 86400) {
    const days = Math.floor(seconds / 86400);
    return `${days} ${getNounEnding(days, "день", "дня", "дней")}`;
  } else if (seconds >= 3600) {
    const hours = Math.floor(seconds / 3600);
    return `${hours} ${getNounEnding(hours, "час", "часа", "часов")}`;
  } else if (seconds >= 60) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} ${getNounEnding(minutes, "минута", "минуты", "минут")}`;
  } else {
    return `${seconds} ${getNounEnding(seconds, "секунда", "секунды", "секунд")}`;
  }
}

function getNounEnding(number: number, form1: string, form2: string, form3: string): string {
  const absNumber = Math.abs(number);
  const lastDigit = absNumber % 10;
  const lastTwoDigits = absNumber % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 19) {
    return form3;
  }

  if (lastDigit === 1) {
    return form1;
  }

  if (lastDigit >= 2 && lastDigit <= 4) {
    return form2;
  }

  return form3;
}

export { getNounEnding, formatTime };
