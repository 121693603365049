import { LabelValueList } from "../models/types/LabelValue";
import dayjs from "dayjs";

export const mapMonthList = (data: LabelValueList): LabelValueList => {
  return data.map((it) => ({
    label: it.label ? dayjs(it.label as string).format("DD") : it.label || "N/A",
    value: it.value ? Number(it.value) : 0,
  }));
};

export const mapValuesFromStringToNumber = (data: LabelValueList): LabelValueList => {
  return data.map((it) => ({
    label: it.label,
    value: it.value ? Number(it.value) : 0,
  }));
};

export const convertListStatisticsToObj = (values: LabelValueList) => {
  return values.reduce((result: any, item) => {
    const { label, value } = item;
    result[label as any] = Number(value);
    return result;
  }, {});
};
