import { CSSProperties } from "react";
import { Pageable } from "../models/types/Pageable";
import { SurveyInstanceFilters } from "../models/types/SurveyInstanceFilters";

export const FullViewPortStyles: CSSProperties = {
  height: "100vh",
  margin: 0,
  padding: 0,
};

export const DisplayFlexStyles: CSSProperties = {
  width: "100%",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
};

export const DisplayGridStyles: CSSProperties = {
  width: "100%",
  display: "grid",
  justifyContent: "center",
  textAlign: "center",
};

export const SurveyInstanceFiltersInitial: SurveyInstanceFilters = {
  answersFilter: {},
  finished: true,
  notFinished: true,
  searchContact: undefined,
  from: undefined,
  to: undefined,
};

export const PageableInitial: Pageable = {
  page: 0,
  size: 10,
};
