import { Button, Checkbox, DatePicker, DatePickerProps } from "antd";
import { FC, useCallback } from "react";
import { RangePickerProps } from "antd/es/date-picker";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { StylesDisplayFlex, useDatesFilter, useStatusesFilter } from "./helpers";
import { Dayjs } from "dayjs";
import { SurveyInstanceFilters } from "../../models/types/SurveyInstanceFilters";
import { SurveyInstanceFiltersInitial } from "../../helpers/ObjectConstants";
import { DefaultDateTimeFormat } from "../../helpers/StringConstants";

type Props = {
  filters: SurveyInstanceFilters;
  setFilters: (f: SurveyInstanceFilters) => void;
};

const Filters: FC<Props> = (props) => {
  const { filters, setFilters } = props;
  const { selectedDates, setSelectedDates, resetDates } = useDatesFilter();
  const { selectedStatuses, setSelectedStatuses, resetStatuses } = useStatusesFilter();

  const onChange = useCallback(
    (value: DatePickerProps["value"] | RangePickerProps["value"]) => {
      if (value === null) {
        resetDates();
      } else if (Array.isArray(value)) {
        setSelectedDates(value);
      }
    },
    [resetDates, setSelectedDates],
  );

  const onStatusChange = useCallback(
    (checkedValue: Array<CheckboxValueType>) => {
      if (Array.isArray(checkedValue)) {
        setSelectedStatuses(checkedValue);
      }
    },
    [setSelectedStatuses],
  );

  const applyFilters = useCallback(() => {
    const obj = {
      ...filters,
      ...(!!selectedDates[0] && !!selectedDates[1]
        ? {
            from: (selectedDates[0] as Dayjs).format(DefaultDateTimeFormat),
            to: (selectedDates[1] as Dayjs).format(DefaultDateTimeFormat),
          }
        : { from: undefined, to: undefined }),
      finished: Boolean(selectedStatuses.find((it) => it === "finished")),
      notFinished: Boolean(selectedStatuses.find((it) => it === "not-finished")),
    };
    if (
      obj.notFinished !== filters.notFinished ||
      obj.finished !== filters.finished ||
      obj.from !== filters.from ||
      obj.to !== filters.to
    ) {
      setFilters(obj);
    }
  }, [filters, selectedDates, selectedStatuses]);

  const resetFilters = useCallback(() => {
    setFilters(SurveyInstanceFiltersInitial);
    resetDates();
    resetStatuses();
  }, [setFilters, resetDates, resetStatuses]);

  return (
    <div style={{ ...StylesDisplayFlex, margin: "24px 0" }}>
      <div style={{ ...StylesDisplayFlex }}>
        <DatePicker.RangePicker
          style={{ marginRight: 24 }}
          showTime={{ format: "HH:mm" }}
          format="YYYY-MM-DD HH:mm"
          value={selectedDates}
          onChange={onChange}
          inputReadOnly
        />
        <Checkbox.Group
          onChange={onStatusChange}
          value={selectedStatuses}
          options={[
            { label: "Завершенные", value: "finished" },
            { label: "Не завершенные", value: "not-finished" },
          ]}
        />
      </div>
      <div style={{ ...StylesDisplayFlex, marginLeft: "auto", gap: 10 }}>
        <Button onClick={applyFilters} type={"primary"}>
          Поиск
        </Button>
        <Button onClick={resetFilters} type={"default"}>
          Сбросить фильтры
        </Button>
      </div>
    </div>
  );
};

export default Filters;
