import { Navigate, Route, Routes } from "react-router-dom";

import { AppPrefix } from "../helpers/StringConstants";
import { ApplicationPaths } from "../models/enums/ApplicationPaths";
import ReviewPage from "../screens/Reviews";
// import ReportsPage from "../screens/Reports";
import AnswersPage from "../screens/Answers";

export const PrivateRoutes = () => {
  return (
    <Routes>
      <Route path={AppPrefix}>
        <Route path={":shareId/share/:uuid"}>
          <Route element={<Navigate to={ApplicationPaths.REVIEWS} />} index />
          <Route path={ApplicationPaths.REVIEWS} element={<ReviewPage />} />
          {/*<Route path={ApplicationPaths.REPORTS} element={<ReportsPage />} />*/}
          <Route path={ApplicationPaths.ANSWERS} element={<AnswersPage />} />
        </Route>
      </Route>
    </Routes>
  );
};
