import { CSSProperties, FC } from "react";

import { ChildrenNode } from "../models/types/ChildrenNode";
import Navigation from "./Navigation";
import { useAppContext } from "../application/context";
import { Result, Spin } from "antd";

const ContainerStyles: CSSProperties = {
  flexDirection: "column",
  overflowX: "hidden",
  maxWidth: "100vw",
  overflowY: "auto",
  display: "flex",
  height: "100vh",
  width: "100vw",
};

const ContentStyles: CSSProperties = {
  flex: 1,
  margin: 30,
  padding: 35,
  borderRadius: 10,
  position: "relative",
  boxSizing: "border-box",
  backgroundColor: "#FFFFFF",
  width: "calc(100% - 60px)",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
};

const Wrapper = ({ children }: ChildrenNode) => {
  const { loading, error } = useAppContext();

  if (error) {
    return (
      <Result
        status="404"
        title="Ссылка устарела."
        subTitle="К сожалению, страница, которую вы посетили, не существует."
      />
    );
  }

  if (loading) {
    return (
      <div
        style={{
          gap: 20,
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Spin size={"large"} />
        Загрузка
      </div>
    );
  }

  return (
    <>
      <Navigation />
      {children}
    </>
  );
};

const ApplicationLayout: FC<ChildrenNode> = ({ children }) => {
  return (
    <div style={ContainerStyles}>
      <div style={ContentStyles}>
        <Wrapper children={children} />
      </div>
    </div>
  );
};

export default ApplicationLayout;
