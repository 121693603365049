import { CSSProperties } from "react";

import { SpaceDirection } from "../models/enums/SpaceDirection";
import { DisplayFlexStyles, DisplayGridStyles } from "./ObjectConstants";
import { ApplicationPaths } from "../models/enums/ApplicationPaths";

export const SpaceDirections: Record<SpaceDirection, CSSProperties> = {
  [SpaceDirection.HORIZONTAL]: DisplayFlexStyles,
  [SpaceDirection.VERTICAL]: DisplayGridStyles,
};

export const ApplicationPathsTitle: Record<ApplicationPaths, string> = {
  [ApplicationPaths.REVIEWS]: "Обзор",
  // [ApplicationPaths.REPORTS]: "Отчёты",
  [ApplicationPaths.ANSWERS]: "Ответы",
};
