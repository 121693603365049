import { createContext, FC, useCallback, useContext, useEffect, useState } from "react";
import { ChildrenNode } from "../models/types/ChildrenNode";
import { useLocation, useNavigate } from "react-router-dom";
import { useContextApi } from "../requests/ContextApi";
import { ShareContext } from "../models/types/ShareContext";
import { AccessRestriction } from "../models/enums/AccessRestriction";
import { ShareTypes } from "../models/enums/ShareTypes";
import { getKeycloakAuthUrl } from "../helpers/Keyckoak";
import { ShareStatus } from "../models/enums/ShareStatus";
import { useReviewsApi } from "../requests/ReviewsApi";
import { AccessTokenKey, CtxDatKey, RedirectUrlKey } from "../helpers/StringConstants";
import { useLocalStorage } from "usehooks-ts";

type AppContextState = {
  readonly shareId?: string;
  readonly accessToken?: string;
  readonly data: ShareContext;
  readonly password?: string;
  readonly loading: boolean;
  readonly error: boolean;
  readonly handlePassword: () => void;
  readonly clearToken: () => void;
  readonly validateAccess: () => void;
};

const AppContext = createContext<AppContextState | null>(null);

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useAppContext must be used within an AppContextProvider");
  }

  return context;
};

const getShareIdFromPathname = (pathname: string) => {
  return pathname.split("/share/")[0]?.replace("/", "") ?? "";
};

export const AppContextProvider: FC<ChildrenNode> = ({ children }) => {
  const [data, setData] = useLocalStorage<ShareContext>(CtxDatKey, {} as ShareContext);
  const [accessToken, setAccessToken] = useLocalStorage<string | undefined>(AccessTokenKey, undefined);
  const [password, setPassword] = useState<string | undefined>();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [redirectUrl, setRedirectUrl] = useLocalStorage(RedirectUrlKey, "");
  const location = useLocation();
  const navigate = useNavigate();
  const shareId = getShareIdFromPathname(location.pathname);
  const { getResultSharedContext, onKeycloakLogin } = useContextApi();
  const { getTotalStatistics } = useReviewsApi(ShareTypes.ANONYMOUS);

  const handlePassword = useCallback(() => {
    const inputPassword = prompt("Пароль* ");
    if (inputPassword) {
      getTotalStatistics(shareId, inputPassword)
        .then(() => {
          setPassword(inputPassword);
          setLoading(false);
        })
        .catch(handlePassword);
    } else {
      handlePassword();
    }
  }, [setPassword, setLoading, getTotalStatistics, shareId]);

  const validateAccess = useCallback(
    (obj?: ShareContext) => {
      const { accessRestriction, shareType, shareStatus } = obj ?? data ?? {};
      if (shareStatus !== ShareStatus.ACTIVE) {
        setError(true);
      } else if (accessRestriction === AccessRestriction.NONE && shareType === ShareTypes.ANONYMOUS) {
        setLoading(false);
      } else if (accessRestriction === AccessRestriction.PASSWORD && shareType === ShareTypes.ANONYMOUS) {
        handlePassword();
      } else if (shareType === ShareTypes.PERSONALISED && !accessToken) {
        setRedirectUrl(location.pathname);
        window.location.replace(getKeycloakAuthUrl());
      } else {
        setLoading(false);
      }
    },
    [data, setLoading, handlePassword, setError, accessToken, location.pathname],
  );

  const initialize = useCallback(
    async (shareId$?: string) => {
      const shouldValidateAccess = !shareId$;
      if (!shareId && !shareId$) return setError(true);
      if (!loading) setLoading(true);

      try {
        const res = await getResultSharedContext(shareId$ ?? shareId);
        setData(res);
        if (shouldValidateAccess) {
          validateAccess(res);
        } else {
          setRedirectUrl("");
          setLoading(false);
        }
      } catch (e) {
        setError(true);
        setLoading(false);
      }
    },
    [shareId, loading, setError, setLoading, setRedirectUrl, getResultSharedContext, setData, validateAccess],
  );

  const handleLogin = useCallback(
    (code: string) => {
      onKeycloakLogin(code)
        .then((jwtToken) => {
          setAccessToken(jwtToken);

          if (!!redirectUrl.trim()) {
            navigate(redirectUrl);
            initialize(data.resultShareId).then().catch();
          }
        })
        .catch(() => {
          setError(true);
        });
    },
    [setAccessToken, setError, redirectUrl, initialize],
  );

  const clearToken = useCallback(() => {
    setAccessToken(undefined);
  }, [setAccessToken]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const code = urlParams.get("code");

    if (code) {
      handleLogin(code);
    } else {
      initialize().then();
    }
  }, []);

  const value: AppContextState = {
    accessToken: accessToken,
    shareId: shareId,
    data: data,
    password: password,
    error: error,
    loading: loading,
    handlePassword: handlePassword,
    clearToken: clearToken,
    validateAccess: validateAccess,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};
