import React, { Key, useCallback, useRef, useState } from "react";
import { CheckboxValueType } from "antd/es/checkbox/Group";
import { ColumnsType, ColumnType as AntdColumnType } from "antd/es/table";
import { Button, Input, InputRef, Space } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { AnswerType, ColumnType, Instance } from "../../models/types/Instance";
import { renderNullableDate, renderNullableText } from "../../helpers/RenderNullable";
import { SurveyInstanceFilters } from "../../models/types/SurveyInstanceFilters";
import { SurveyInstanceFiltersInitial } from "../../helpers/ObjectConstants";

export const StylesDisplayFlex = { display: "flex", alignItems: "center" };
export const DatesFilterInitial: [string, string] = ["", ""];
export const StatusesFilterInitial = ["finished", "not-finished"];

export const useDatesFilter = () => {
  const [selectedDates, setSelectedDates] = useState<[any, any]>(DatesFilterInitial);

  const resetDates = useCallback(() => {
    setSelectedDates(DatesFilterInitial);
  }, [setSelectedDates]);

  return { selectedDates, setSelectedDates, resetDates };
};

export const useStatusesFilter = () => {
  const [selectedStatuses, setSelectedStatuses] = useState<Array<CheckboxValueType>>(StatusesFilterInitial);

  const resetStatuses = useCallback(() => {
    setSelectedStatuses(StatusesFilterInitial);
  }, [setSelectedStatuses]);

  return { selectedStatuses, setSelectedStatuses, resetStatuses };
};

const RECEIVER_KEY = "receiver";

export const useSearchFiler = () => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef<InputRef>(null);
  const [filters, setFilters] = useState<SurveyInstanceFilters>(SurveyInstanceFiltersInitial);

  const handleSearch = (searchQ: Key, dataIndex: string) => {
    onAnswersFilterChange(dataIndex, searchQ as string);
    setSearchText(searchQ as string);
    setSearchedColumn(dataIndex);
  };

  const handleReset = useCallback(
    (dataIndex: string, clearFilters?: () => void) => {
      onAnswersFilterChange(dataIndex, undefined);
      if (clearFilters) clearFilters();
      setSearchText("");
    },
    [filters],
  );

  const onAnswersFilterChange = useCallback(
    (key: string, value: string | undefined) => {
      if (key === RECEIVER_KEY) {
        return setFilters((prev) => ({ ...prev, searchContact: value }));
      }

      setFilters((prev) => ({ ...prev, answersFilter: { ...prev.answersFilter, [key]: value } }));
    },
    [filters],
  );

  const getColumnSearchProps = (dataIndex: string): AntdColumnType<Instance> => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters, close }) => (
      <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
        <Input
          ref={searchInput}
          value={selectedKeys[0]}
          placeholder={"Поиск"}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys[0], dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Space direction={"horizontal"}>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys[0], dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Поиск
          </Button>
          <Button onClick={() => handleReset(dataIndex, clearFilters)} size="small" style={{ width: 90 }}>
            Сбросить
          </Button>
          <Button type="link" size="small" onClick={() => close()} style={{ margin: 0 }}>
            Закрыть
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? "#1677ff" : undefined }} />,
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });

  return { searchText, searchedColumn, getColumnSearchProps };
};

export const Columns = (questions: ColumnType = {}, allQuestions: ColumnType = {}): ColumnsType<Instance> => {
  const filters = useSearchFiler();
  let cols: ColumnsType<Instance> = [
    {
      key: "receiver",
      width: 200,
      fixed: "left",
      dataIndex: "receiver",
      title: "Контакт",
      ...filters.getColumnSearchProps(RECEIVER_KEY),
    },
    {
      width: 350,
      fixed: "left",
      key: "dateAndTime",
      title: "Дата, время",
      render: (record: Instance) => {
        return (
          <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
            <span>{renderNullableDate(record.startedAt)},</span>
            <span>{convertTime(record.avgFinishedTime)},</span>
            <span>{calculateAnsweredPercentage(allQuestions, record.answers)}% Отвечено</span>
          </div>
        );
      },
    },
  ];
  const questionsIds = Object.keys(questions);
  if (questionsIds.length) {
    cols = [
      ...cols,
      ...questionsIds.map((qId) => {
        return {
          key: qId,
          width: 200,
          title: questions[qId].question,
          ...filters.getColumnSearchProps(qId),
          render: (rec: Instance) => renderNullableText(rec.answers[qId]),
        };
      }),
    ];
  }

  return cols;
};

const convertTime = (avgTime: number): string => {
  const seconds = avgTime % 60;
  const minutes = Math.floor((avgTime / 60) % 60);
  const hours = Math.floor(avgTime / 3600);

  let formattedTime = "";
  if (hours > 0) {
    formattedTime += `${hours} час ${minutes} мин`;
  } else if (minutes > 0) {
    formattedTime += `${minutes} мин`;
  } else {
    formattedTime += `${seconds} сек`;
  }

  return formattedTime;
};

export const calculateAnsweredPercentage = (columns: ColumnType, answers: AnswerType): number => {
  const totalQuestions = Object.keys(columns).length;
  const totalAnswers = Object.keys(answers).length;

  return Math.round((totalAnswers / totalQuestions) * 100);
};

export const filterColumns = (columns: ColumnType, ids: Array<string>) => {
  return ids.reduce((filteredColumns: ColumnType, id: string) => {
    if (columns.hasOwnProperty(id)) {
      filteredColumns[id] = columns[id];
    }
    return filteredColumns;
  }, {});
};
