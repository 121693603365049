import { LabelValueList } from "../models/types/LabelValue";
import { ChartViewTypes } from "../models/enums/ChartViewTypes";
import { useCallback } from "react";
import axios from "axios";
import { ShareTypes } from "../models/enums/ShareTypes";
import { MainApiUrl } from "../helpers/StringConstants";

interface IReviewsApi {
  getAnswersByDays: (shareId: string, date: string, password?: string) => Promise<LabelValueList>;
  getAverageTime: (shareId: string, type: ChartViewTypes, password?: string) => Promise<LabelValueList>;
  getTotalStatistics: (shareId: string, password?: string) => Promise<LabelValueList>;
  getInteractionsByCategory: (shareId: string, type: ChartViewTypes, password?: string) => Promise<LabelValueList>;
}

const MainUrl = MainApiUrl;
const toUrl = (parts: string[]) => MainUrl.concat(parts.map((e) => e.toLowerCase()).join("/"));

export const useReviewsApi = (shareType: ShareTypes): IReviewsApi => {
  const getAnswersByDays = useCallback(async (shareId: string, month: string, password?: string) => {
    try {
      const { data } = await axios.post<LabelValueList>(
        toUrl(["/chart", shareType, "answers-by-day"]),
        { month },
        { params: { shareId, password } },
      );
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  const getAverageTime = useCallback(async (shareId: string, type: ChartViewTypes, password?: string) => {
    try {
      const { data } = await axios.post<LabelValueList>(
        toUrl(["/chart", shareType, "average-time"]),
        {},
        { params: { shareId, type, password } },
      );
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  const getTotalStatistics = useCallback(async (shareId: string, password?: string) => {
    try {
      const { data } = await axios.post<LabelValueList>(
        toUrl(["/chart", shareType, "total-statistics"]),
        {},
        { params: { shareId, password } },
      );
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  const getInteractionsByCategory = useCallback(async (shareId: string, type: ChartViewTypes, password?: string) => {
    try {
      const { data } = await axios.post<LabelValueList>(
        toUrl(["/chart", shareType, "interactions-by-category"]),
        {},
        { params: { shareId, type, password } },
      );
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  return {
    getAnswersByDays: getAnswersByDays,
    getAverageTime: getAverageTime,
    getTotalStatistics: getTotalStatistics,
    getInteractionsByCategory: getInteractionsByCategory,
  };
};
