import React, { FC, Key, useCallback, useEffect, useState } from "react";
import { Button, Checkbox, message, Modal, Table } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import { Columns, filterColumns } from "./helpers";
import { SurveyInstanceFilters, SurveyInstanceResponseType } from "../../models/types/SurveyInstanceFilters";
import { Pageable } from "../../models/types/Pageable";
import { ColumnType, Instance } from "../../models/types/Instance";

type Props = {
  pageable: Pageable;
  setPageable: React.Dispatch<React.SetStateAction<Pageable>>;
  filters: SurveyInstanceFilters;
  setFilters: React.Dispatch<React.SetStateAction<SurveyInstanceFilters>>;
  data?: SurveyInstanceResponseType;
};

const TableComponent: FC<Props> = (props) => {
  const { pageable, setPageable, filters, setFilters, data } = props;
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [selectedColumns, setSelectedColumns] = useState<ColumnType | undefined>();

  const rowSelection = {
    onChange: (selectedRowKeys: Key[], selectedRows: Array<Instance>) => {
      setSelectedRows(selectedRows.map((it) => it.instanceId));
    },
  };

  const onColumnsChange = useCallback(
    (cols: ColumnType) => {
      const updatedAnswersFilters = Object.keys(cols).reduce((result: Record<string, string | undefined>, key) => {
        if (filters.answersFilter[key]) {
          result[key] = filters.answersFilter[key];
        }
        return result;
      }, {});

      const wasUpdated = Object.keys(filters.answersFilter).find((key) => !updatedAnswersFilters[key]);

      setSelectedColumns(cols);
      if (wasUpdated) {
        setFilters((prev) => ({ ...prev, answersFilter: updatedAnswersFilters }));
      }
    },
    [filters],
  );

  useEffect(() => {
    if (!selectedColumns) {
      setSelectedColumns(data?.columns);
    }
  }, [data?.columns]);

  const onPageChange = useCallback((pageNumber: number, pageSize: number) => {
    setPageable((prev) => ({ ...prev, page: pageNumber - 1, size: pageSize }));
  }, []);

  return (
    <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
      <Actions
        filters={filters}
        allColumns={data?.columns}
        selectedColumns={selectedColumns}
        onColumnsChange={onColumnsChange}
      />
      <Table
        pagination={{
          size: "small",
          showSizeChanger: true,
          onChange: onPageChange,
          total: pageable.total,
          pageSize: pageable.size,
          current: pageable.page + 1,
          defaultPageSize: pageable.size,
        }}
        scroll={{ x: 1000 }}
        style={{ marginTop: 24 }}
        dataSource={data?.instances}
        columns={Columns(selectedColumns, data?.columns)}
        rowKey={(record) => record?.instanceId + ""}
        rowSelection={{ type: "checkbox", ...rowSelection, selectedRowKeys: selectedRows }}
      />
    </div>
  );
};

type ActionProps = {
  allColumns?: ColumnType;
  selectedColumns?: ColumnType;
  onColumnsChange: (cols: ColumnType) => void;
  filters: SurveyInstanceFilters;
};

const Actions = (props: ActionProps) => {
  const { selectedColumns: selectedColumnsReadonly, allColumns = {}, onColumnsChange } = props;

  const [columnsModalVisible, setColumnsModalVisible] = useState(false);
  const [selectedColumns, setSelectedColumns] = useState<Array<string>>([]);

  const handleColumnsChange = useCallback(() => {
    if (!selectedColumns.length) {
      return message.warning("Колонки не найдены");
    }
    setColumnsModalVisible(true);
  }, [selectedColumns]);

  const handleCloseColumnsChangeModal = useCallback(() => {
    setColumnsModalVisible(false);
  }, []);

  const handleSelect = useCallback((values: Array<string>) => {
    if (!values.length) {
      return message.error("Минимум одна колонка должна быть выбрана");
    }
    setSelectedColumns(values);
  }, []);

  const handleSubmit = useCallback(() => {
    const filtered = filterColumns(allColumns, selectedColumns);
    onColumnsChange(filtered);
    setColumnsModalVisible(false);
  }, [selectedColumns]);

  useEffect(() => {
    if (selectedColumnsReadonly) {
      setSelectedColumns(Object.keys(selectedColumnsReadonly));
    }
  }, [selectedColumnsReadonly]);

  return (
    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end", gap: 12 }}>
      <Button
        type={"default"}
        style={{ marginLeft: 20 }}
        onClick={handleColumnsChange}
        icon={<MenuOutlined style={{ rotate: "90deg" }} />}
      >
        Колонки
      </Button>

      {selectedColumns && allColumns && (
        <Modal
          width={400}
          onOk={handleSubmit}
          open={columnsModalVisible}
          title={"Выберите колонки"}
          onCancel={handleCloseColumnsChangeModal}
        >
          <div style={{ display: "grid", width: "100%" }}>
            <Checkbox.Group
              options={Object.keys(allColumns).map((questionId) => ({
                label: allColumns[questionId]?.question,
                value: questionId,
              }))}
              onChange={(values) => handleSelect(values as Array<string>)}
              value={selectedColumns}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default TableComponent;
