import React, { FC, useLayoutEffect } from "react";
import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { LabelValueList } from "../../models/types/LabelValue";
import { getRandomKey } from "../../helpers/FunctionConstants";

type Props = {
  readonly data: LabelValueList;
  readonly height: number;
  readonly key?: string;
  readonly legend?: boolean;
};

const DonutChartComponent: FC<Props> = ({ data, height, key = getRandomKey(), legend }) => {
  useLayoutEffect(() => {
    const root = am5.Root.new(key);
    root.setThemes([am5themes_Animated.new(root)]);

    let chart = root.container.children.push(
      am5percent.PieChart.new(root, {
        radius: am5.percent(70),
        innerRadius: am5.percent(50),
        layout: root.horizontalLayout,
      }),
    );

    const series = chart.series.push(
      am5percent.PieSeries.new(root, {
        valueField: "value",
        categoryField: "label",
        alignLabels: false,
      }),
    );

    series.labels.template.setAll({
      textType: "circular",
      centerX: 0,
      centerY: 0,
    });

    series.data.setAll(data);

    if (legend) {
      series.labels.template.set("visible", false);
      series.ticks.template.set("visible", false);

      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerY: am5.percent(50),
          y: am5.percent(50),
          layout: root.verticalLayout,
        }),
      );

      legend.valueLabels.template.setAll({ textAlign: "right" });

      legend.labels.template.setAll({
        maxWidth: 350,
        width: 350,
        oversizedBehavior: "wrap",
      });

      legend.data.setAll(series.dataItems);
    }

    series.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, []);

  return <div id={key} style={{ width: "100%", height }} />;
};

export default DonutChartComponent;
