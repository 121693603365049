import { useCallback } from "react";
import axios from "axios";
import { ShareContext } from "../models/types/ShareContext";
import { MainApiUrl } from "../helpers/StringConstants";

interface IContextApi {
  getResultSharedContext: (shareId: string) => Promise<ShareContext>;
  onKeycloakLogin: (code: string) => Promise<string>;
}

const MainUrl = MainApiUrl;
const toUrl = (parts: string[]) => MainUrl.concat(parts.join("/"));

export const useContextApi = (): IContextApi => {
  const getResultSharedContext = useCallback(async (shareId: string) => {
    try {
      const { data } = await axios.get<ShareContext>(toUrl(["/results", "shared", shareId]));
      return Promise.resolve(data);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  const onKeycloakLogin = useCallback(async (code: string) => {
    try {
      const url = window._env_.API_BASE_URL.concat(window._env_.SURVEY_MANAGEMENT_API_PATH, "/auth/login");
      const {
        data: { jwtToken },
      } = await axios.post<{ jwtToken: string }>(url, {
        code: code,
        redirectUri: window.location.origin,
      });
      return Promise.resolve(jwtToken);
    } catch (e) {
      return Promise.reject(e);
    }
  }, []);

  return {
    getResultSharedContext: getResultSharedContext,
    onKeycloakLogin: onKeycloakLogin,
  };
};
