import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { ChartViewTypes } from "../../models/enums/ChartViewTypes";
import StatisticsLayout from "../../components/StatisticsLayout";
import { ColumnChart } from "../../components/Charts";
import { LabelValueList } from "../../models/types/LabelValue";
import { mapValuesFromStringToNumber } from "../../helpers/ResultsPageHelpres";
import { useReviewsApi } from "../../requests/ReviewsApi";
import { useAppContext } from "../../application/context";

type Props = { readonly shareId: string };

const AverageTime: FC<Props> = ({ shareId }) => {
  return (
    <div style={{ display: "flex", alignItems: "center", gap: 24 }}>
      <Chart shareId={shareId} type={ChartViewTypes.DEVICE} title={"Среднее время"} subTitle={"По типу устройства"} />
      <Chart shareId={shareId} type={ChartViewTypes.LANGUAGE} subTitle={"По языку"} />
      <Chart shareId={shareId} type={ChartViewTypes.MAILING} subTitle={"По типу рассылки"} />
    </div>
  );
};

export default AverageTime;

type ChartProps = {
  type: ChartViewTypes;
  title?: string;
  subTitle: string;
  shareId: string;
};

const Chart: FC<ChartProps> = ({ shareId, type, title, subTitle }) => {
  const { data, loading } = useLoadDataByCategory(type, shareId);
  return (
    <StatisticsLayout
      title={title}
      padding={"40px"}
      subTitle={subTitle}
      children={<ColumnChart data={data} height={350} loading={loading} />}
    />
  );
};

const useLoadDataByCategory = (type: ChartViewTypes, shareId: string) => {
  const { data: ctxData, password } = useAppContext();
  const { getAverageTime } = useReviewsApi(ctxData.shareType);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<LabelValueList>([]);

  const loadData = useCallback(() => {
    setLoading(true);
    getAverageTime(shareId, type, password)
      .then((res) => setData(mapValuesFromStringToNumber(res)))
      .catch(console.log)
      .finally(() => setLoading(false));
  }, [type]);

  useEffect(() => loadData(), []);

  return useMemo(() => {
    return { data, loading, reRetch: loadData };
  }, [data, loading]);
};
