import { FC } from "react";
import AnswersByDays from "./answersByDays";
import GeneralStatistics from "./generalStatistics";
import InteractionsByCategories from "./interactionsByCategories";
import AverageTime from "./averageTime";
import { useParams } from "react-router-dom";
import { Empty } from "antd";

const ReviewPage: FC = () => {
  const { shareId } = useParams();

  if (!shareId) {
    return (
      <Empty
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      />
    );
  }

  return (
    <div>
      <GeneralStatistics showTitle shareId={shareId} />
      <AnswersByDays shareId={shareId} />
      <InteractionsByCategories shareId={shareId} />
      <AverageTime shareId={shareId} />
    </div>
  );
};

export default ReviewPage;
