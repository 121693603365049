import "./index.css";
import { FC, ReactNode } from "react";
import { Empty } from "antd";
import { GeneralStatisticType } from "../models/types/GeneralStatisticType";
import Loading from "./Loading";
import { formatTime } from "../helpers/MapSeconds";
import { CalendarIcon, ClockIcon, PercentageIcon, PlayIcon, QuestionIcon } from "./Icons";
import { EyeOutlined } from "@ant-design/icons";

const Height = 150;

type Props = {
  readonly loading: boolean;
  readonly data?: GeneralStatisticType;
  readonly showQuestionsQuantity?: boolean;
};

const GeneralStatisticsComponent: FC<Props> = ({ loading, data, showQuestionsQuantity }) => {
  if (loading) {
    return <Loading height={Height} />;
  }

  if (!data) {
    return (
      <Empty
        style={{
          height: Height,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "center",
        }}
      />
    );
  }

  return (
    <Layout className={showQuestionsQuantity ? "general-statistics-column-layout" : "general-statistics-row-layout"}>
      <Element label={"Просмотрено"} value={data.visited} icon={<EyeOutlined style={{ fontSize: 25 }} />} />
      <Element label={"Начато опросов"} value={data.started} icon={<PlayIcon />} />
      {showQuestionsQuantity && (
        <Element label={"Вопросов в опросе"} value={data.question_total} icon={<QuestionIcon />} />
      )}
      <Element label={"Среднее время завершения"} value={formatTime(data.avg_finished_time)} icon={<ClockIcon />} />
      <Element label={"Завершено"} value={data.finished} icon={<CalendarIcon />} />
      <Element label={"Доля завершенных"} value={data.percent_finished.toFixed(1)} icon={<PercentageIcon />} />
    </Layout>
  );
};

type ElementProps = {
  label: string;
  value: number | string | undefined;
  icon: ReactNode;
  grid?: "column" | "row";
};

const Element: FC<ElementProps> = ({ label, value, icon }) => {
  return (
    <div className={"general-statistics-column-element"}>
      <div style={{ display: "grid", justifyContent: "center", textAlign: "center" }}>
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginBottom: 10 }}>
          <span style={{ width: 25, height: 25, marginRight: 15 }}>{icon}</span>
          <span style={{ fontWeight: 600, fontSize: 22, color: "#000000" }}>{value}</span>
        </div>
        <span style={{ color: "#8D8D8D", fontSize: 18, fontWeight: 500 }}>{label}</span>
      </div>
    </div>
  );
};

type LayoutProps = { children: ReactNode; className: string };

const Layout = ({ children, className }: LayoutProps) => {
  return (
    <div
      style={{ gap: 8, height: Height, display: "flex", alignItems: "center", flexWrap: "wrap" }}
      className={className}
    >
      {children}
    </div>
  );
};

export default GeneralStatisticsComponent;
