import { FC } from "react";
import { Empty } from "antd";
import { LabelValue } from "../../models/types/LabelValue";
import Loading from "../Loading";
import ColumnChartComponent from "./ColumnChart";
import DonutChartComponent from "./DonutChart";

type Props = {
  readonly legend?: boolean;
  readonly loading: boolean;
  readonly data: Array<LabelValue>;
  readonly height: number;
};

const ColumnChart: FC<Omit<Props, "legend">> = ({ loading, data, height }) => {
  if (loading) {
    return <Loading height={height} />;
  }

  if (!data.length) {
    return (
      <Empty
        style={{ height, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}
      />
    );
  }

  return <ColumnChartComponent data={data} height={height} />;
};

const DonutChart: FC<Props> = ({ loading, data, height, legend }) => {
  if (loading) {
    return <Loading height={height} />;
  }

  if (!data.length) {
    return (
      <Empty
        style={{ height, justifyContent: "center", alignItems: "center", display: "flex", flexDirection: "column" }}
      />
    );
  }

  return <DonutChartComponent data={data} height={height} legend={legend} />;
};

export { ColumnChart, DonutChart };
