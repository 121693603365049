import { FC, useCallback, useEffect, useState } from "react";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { LabelValue } from "../../models/types/LabelValue";
import { DefaultDateFormat } from "../../helpers/StringConstants";
import StatisticsLayout from "../../components/StatisticsLayout";
import { mapMonthList } from "../../helpers/ResultsPageHelpres";
import { ColumnChart } from "../../components/Charts";
import { useReviewsApi } from "../../requests/ReviewsApi";
import { useAppContext } from "../../application/context";

type Props = { readonly shareId: string };

const AnswersByDays: FC<Props> = ({ shareId }) => {
  const { data: ctxData, password } = useAppContext();
  const { getAnswersByDays } = useReviewsApi(ctxData.shareType);
  const [selectedFilter, setSelectedFilter] = useState<Dayjs>(dayjs(new Date()));
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<Array<LabelValue>>([]);

  const onFilter = useCallback((value: Dayjs | null) => {
    if (value) {
      setSelectedFilter(value);
      loadData(value);
    }
  }, []);

  const loadData = useCallback(
    (date?: Dayjs) => {
      setLoading(true);
      getAnswersByDays(shareId, (date ?? selectedFilter).format(DefaultDateFormat), password)
        .then((res) => setData(mapMonthList(res)))
        .catch(console.log)
        .finally(() => setLoading(false));
    },
    [selectedFilter],
  );

  useEffect(() => {
    loadData();
  }, []);

  return (
    <StatisticsLayout title={"Ответы по дням"}>
      <DatePicker
        inputReadOnly
        picker={"month"}
        allowClear={false}
        onChange={onFilter}
        format={"MMMM YYYY"}
        value={selectedFilter}
        style={{ width: 160, marginBottom: 24 }}
      />
      <ColumnChart data={data} loading={isLoading} height={400} />
    </StatisticsLayout>
  );
};

export default AnswersByDays;
