import "./index.css";
import { FC } from "react";
import { LoadingOutlined } from "@ant-design/icons";

const STYLE = (height: number) => ({
  width: "100%",
  height,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

type Props = { readonly height: number };

const Loading: FC<Props> = ({ height }) => {
  return (
    <div style={STYLE(height)}>
      <div style={{ display: "grid", justifyItems: "center" }}>
        <LoadingOutlined className={"data-loading-indicator"} />
        <h3 style={{ fontSize: 17, marginTop: 10 }}>Загрузка . . .</h3>
      </div>
    </div>
  );
};

export default Loading;
